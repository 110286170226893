.color-primary {
    color: var(--main-blue);
}

.title {
    font-size: 1.2em;
    font-family: monospace;
    font-weight: bolder;
    font-style: italic;
}

.bg-custom {
    background-image: linear-gradient(to right, #0b414f, #07161a);
}

.hide {
    visibility: 'hidden'; 
}

a:hover {
    cursor: pointer;
}

.nav-link:hover {
    color:white;
}

.nav-logo {
    max-width: 75px;
}

@media (max-width: 767px) {
    .nav-logo {
        max-width: 50px;
    }

    .nav-choice {
        font-size: 0.9em;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .nav-logo {
        max-width: 50px;
    }

    .nav-choice {
        font-size: 1.1em;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .nav-logo {
        max-width: 75px;
    }

    .nav-choice {
        font-size: 1.2em;
    }
}

@media (min-width: 1200px) {
    .nav-logo {
        max-width: 75px;
    }

    .nav-choice {
        font-size: 1.4em;
    }
}