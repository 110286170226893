.bg-custom {
    background-image: linear-gradient(to bottom, #030b0d, #061c26);
}

.carousel-item img {
    object-fit: contain;
    width: 100%;
    height: 100% !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  font-weight: bolder;
  color: var(--main-blue);
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  font-weight: bolder;
  color: var(--main-blue);
}


/* Media queries for different screen sizes */
@media (max-width: 767px) {
    .carousel-item {
        height: 200px;
    }

    .carousel-control-next-icon:after
    {
        font-size: 25px;
    }

    .carousel-control-prev-icon:after {
        font-size: 25px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    /* Adjust the image height for medium-sized screens */
    .carousel-item {
        height: 400px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    /* Adjust the image height for larger screens */
    .carousel-item {
        height: 600px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px){
    /* Adjust the image height for extra-large screens */
    .carousel-item {
        height: 800px;
    }
}

@media (min-width: 1400px) {
    /* Adjust the image height for extra-large screens */
    .carousel-item {
        height: 1000px;
    }

}