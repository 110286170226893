.card-image {
    min-width: 200px;
    width: 200px;
    height: 200px;
    min-height: 200px;
    border: 6px solid #f4f3f3;
}

@media (max-width: 767px) {
    .card-image {
        min-width: 160px;
        max-width: 160px;
        width: 160px;

        height: 160px;
        min-height: 160px;
        max-height: 160px;
        border: 2px solid #f4f3f3;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .card-image {
        min-width: 170px;
        max-width: 170px;
        width: 170px;

        height: 170px;
        min-height: 170px;
        max-height: 170px;
        border: 4px solid #f4f3f3;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .card-image {
        min-width: 150px;
        max-width: 150px;
        width: 150px;

        height: 150px;
        min-height: 150px;
        max-height: 150px;
        border: 6px solid #f4f3f3;
    }
    
}

@media (min-width: 1200px) {
    .card-image {
        min-width: 200px;
        width: 200px;
        height: 200px;
        min-height: 200px;
        border: 6px solid #f4f3f3;
    }
}